import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";

import "./NavBarNewStyle.css";
import firstdraft from "../../firstdraft.svg";

const NavBarNew = () => {
  const [togleV, setTogleV] = useState(false);

  return (
    <div className="container-fluid myBgColor p-2 ">
      <div className="row justify-content-between">
        <div className="col-md-3 col-12 m-0">
          <div className="d-flex justify-content-between">
            <divc className="row ps-3 m-0">
              <div className="col-5 p-0">
                <Link to={"/"}>
                  <img src={firstdraft} alt="" className="navbar-brand p-2" />
                </Link>
              </div>
              <Link to={"/"} className="col-5 p-0 logoText">
                <span className="fs-2">Lokkit</span>
              </Link>
            </divc>
            <div className="myBitton">
              <button
                className="btn btn-sm fs-2 mx-2 p-0 m-0 d-md-none"
                onClick={() => setTogleV((prev) => !prev)}
              >
                <IoIosMenu />
              </button>
            </div>
          </div>
          <h6>Toimimme iStoragen jakelijana</h6>
        </div>
        <div className="col-md-8 float-end col-12 mx-1">
          <div
            className={
              togleV ? "float-end d-block" : "float-end d-none d-md-block"
            }
          >
            <ul className="m-0 d-md-flex resNav" style={{ listStyle: "none" }}>
              <Link to={"/"}>
                <li className="px-3 mx-1 py-2">Etusivu</li>
              </Link>
              <Link to={"/palvelut"}>
                <li className="px-3 mx-1 py-2">Palvelut</li>
              </Link>
              <Link to={"/tuotteet"}>
                <li className="px-3 mx-1 py-2">Tuotteet</li>
              </Link>
              <Link to={"/tietoa-meista"}>
                <li className="px-3 mx-1 py-2">Tietoa meistä</li>
              </Link>
              <Link to={"/ota-yhteytta"}>
                <li className="px-3 mx-1 py-2">Ota yhteyttä</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarNew;
