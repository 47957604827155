import React from "react";
import "./homeStyle.css";
import Usb from "../Home/landingpage/Usb.png";
import Datalocker from "../Home/landingpage/Datalocker.jpg";
import hddsdd from "../Home/landingpage/hddsdd.png";
import Products from "../Home/landingpage/Products.jpg";
import ScrollToTopButton from '../../Layouts/ScrollToTopButton/ScrollToTopButton';

export default function Home() {
     // ScrollButton
     const scrollview = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

  return (
    <div className="home">
      {/* ScrollButton */}
      <ScrollToTopButton scrollview={scrollview} />

        <div className="banner">
          <h1 style={{ fontSize: '30px'}}>Tulossa!</h1>
            <p>Mobiilipalvelin kryptattuun tiedonsiirtoon,<br/>
              palvelinympäristön kloonaukseen/huoltoon ja tietosuojamateriaalin hävittämiseen.
            </p>    
        </div>

      {/* Products and images */}
        <div className="container mt-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 custom-col-md-4 d-flex align-items-center">
              <div className="image-container text-center">
              <h1>DatAshur SD</h1>
              {/* <p className="näytä" style={{ color: 'blue' }}>Näytä lisää</p> */}
              <img src={Usb} alt="Usb Drive" className="img-thumbnail" style={{ border: 'none' }}/>
          </div>
        </div>

        <div className="col-12 col-md-4 custom-col-md-4 d-flex align-items-center">
          <div className="image-container text-center">
            <h1 className="red-title fs-2" style={{ color: 'red' }}>THE SENTRY 5</h1>
            <p>HARDWARE ENCRYPTED USB</p>
            {/* <p className="näytä" style={{ color: 'blue' }}>Näytä lisää</p> */}
            <img src={Datalocker} alt="Data Locker" className="img-thumbnail" style={{ border: 'none' }}/>
          </div>
        </div>

        <div className="col-12 col-md-4 custom-col-md-4 d-flex align-items-center">
          <div className="image-container text-center" >
            <h1>Encrypted HDDs/SSDs</h1>
            {/* <p className="näytä" style={{ color: 'blue' }}>Näytä lisää</p> */}
            <img src={hddsdd} alt="HDDs/SSDs" className="img-thumbnail" style={{ border: 'none' }}/>
            </div>
          </div>
        </div>
      </div>

      {/* Products and images */}
      <div className="primary" style={{ padding: '20px', marginTop: '-80px' }}>
        <div className="primaryText">
          <h1>
            <span style={{ color: 'black' }}>DATASHUR</span>
            <span style={{ color: 'blue' }}> PRO+C</span>
              </h1>
              <p>World's FIRST & ONLY flash drive<br />
              pending FIPS 140-3 Level 3 with USB <br />
              Type-C interface & ultra fast transfer <br />
              speeds</p>
          {/* <button className="custom-button" type="button">Näytä lisää</button> */}
        </div>
      </div>

      {/* Products and images */}
      <div className="row justify-content-center" >
        <div className="col-12 col-md-12 d-flex align-items-end"></div>
          <div className="image-container text-center" style={{ width: '100%', position: 'relative' }}>
            <img src={Products} alt="Product" className="img-thumbnail" style={{ border: 'none' }} />
            <div style={{ position: 'absolute', bottom: '20%', textAlign: 'center', color: 'white' }}>
              <h1>Securing your digital life</h1>
              <p style={{ color: 'white', margin: '30px' }}>State of the art, ultra-secure and easy to use hardware encrypted<br />
                portable data storage devices and cloud encryption solution that<br />
                give you total security and control over your data.</p>
              <p style={{ color: 'blue' }}>Product Evaluation</p>
            </div>
          </div>
        </div>
      </div>
  );
}
