/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "./productStyle.css";
import FlashDrives from "./FlashDrives/FlashDrives";
import HddSdd from "./HDD_SSD/HDD_SSD";

const Products = () => {
  return (
    <div className="container pt-4">
      <hr />
      <h4 className=" text-center">FLASH DRIVES</h4>
      <hr />
      <FlashDrives />
      <hr />
      <h4 className=" text-center">HDD SSD</h4>
      <hr />
      <HddSdd />
    </div>
  );
};

export default Products;
