import React from "react";
import "../productStyle.css";
import { dummyProduct } from "./FlashDrivesData";
import FlashDrivesList from "./FlashDrivesList";

const FlashDrives = () => {
  return (
    <div className="container pt-4">
      <div className="row">
        {dummyProduct.map((product) => (
          <FlashDrivesList product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
};

export default FlashDrives;
