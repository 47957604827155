import pimg1 from "./images/pimg1.jpg";
import pimg2 from "./images/pimg2.jpg";
import pimg3 from "./images/pimg3.jpg";
import pimg4 from "./images/pimg4.jpg";
import pimg5 from "./images/pimg5.jpg";
import pimg6 from "./images/pimg6.jpg";
import pimg7 from "./images/pimg7.jpg";
import cross from "./images/crossed_symbol.png";
import tick from "./images/ticked_symbol.png";
import img4 from "./images/4.png";
import img5 from "./images/5.png";
import img6 from "./images/6.png";

export const dummyProduct = [
  {
    id: 1,
    name: "DATASHUR®",
    image: pimg1,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "4/8/16/32GB",
    desc7: "Interface",
    desc8: "USB 2.0",
    desc9: "Certifications",
    desc10: "FIPS 140-2 Level 3",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Android, Chrome, Thin Clients, Zero Clients Embedded Systems, Citrix and VMware",
  },
  {
    id: 2,
    name: "DATASHUR® BT",
    image: pimg2,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Smartphone Activated",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "16/32/64/128GB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10: "FIPS 140-2 Level 3 certified",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Chrome, Android, Thin Clients, Zero Clients, Embedded Systems, Citrix and VMware",
    desc13: "Dust and water resistant",
    desc14: "IP68 pending",
    desc15: "Size & Weight",
    desc16: "80.10mm x 20.25mm x 10.70mm",
  },
  {
    id: 3,
    name: "DATASHUR® Personal²",
    image: pimg3,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "4/8/16/32/64GB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10: "None",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Chrome, Android, Thin Clients, Zero Clients, Embedded Systems, Citrix and VMware",
  },
  {
    id: 4,
    name: "DATASHUR® PRO",
    image: pimg4,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "4/8/16/32/64/128/256GB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed USB",
    desc9: "Certifications",
    desc10: "FIPS 140-2 Level 3, NLNCSA DEP-V & NATO Restricted Level certified",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Chrome, Android, Thin Clients, Zero Clients, Embedded Systems, Citrix and VMware",
  },
  {
    id: 5,
    name: "DATASHUR® PRO²",
    image: pimg5,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: tick,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "4/8/16/32/64/128/256/512GB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10: "FIPS 140-2 Level 3 Certified",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Android, Chrome, Thin Clients, Zero Clients Embedded Systems, Citrix and VMware",
  },
  {
    id: 6,
    name: "DATASHUR® PRO+C",
    image: pimg6,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "4/8/16/32/64/128/256/512GB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed - USB Type C with USB Type-A adapter included",
    desc9: "Certifications",
    desc10: "FIPS 140-3 Level 3 (pending)",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Chrome, Android, Thin Clients, Zero Clients, Embedded Systems, Citrix and VMware",
  },
  {
    id: 7,
    name: "DATASHUR® SD",
    image: pimg7,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: tick,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "32/64/128/256/512GB - 1TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed - Type C",
    desc9: "Certifications",
    desc10: "FIPS 140-3 Level 3 (pending)",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, Linux, Chrome, Android, Thin Clients, Zero Clients, Embedded Systems, Citrix and VMware",
  },
  {
    id: 8,
    name: "DataLocker Sentry K350",
    image: img4,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "16/64/128/256/512GB",
    desc7: "Interface",
    desc8: "USB-A compatible with USB 3.2 Gen 1, USB 2.0",
    desc9: "Certifications",
    desc10: "FIPS 140-2 Level 3 Common Criteria certified (pending)",
    desc11: "Software free / Compatible with:",
    desc12: "MS Windows, macOS, iPadOS, Linux, Android, Chrome OS, Citrix Ready (XenApp & XenDesktop compatible)",
  },
  {
    id: 9,
    name: "DataLocker Sentry ONE",
    image: img5,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "4/8/16/32/64/128GB",
    desc7: "Interface",
    desc8: "USB 3.2 GEN 1 – Backward compatible with USB 2.0 & 1.1",
    desc9: "Certifications",
    desc10: "FIPS 140-2 Level 3, CSPN Certification",
    desc11: "Software free / Compatible with:",
    desc12: "Windows® 10, 8.1, 8, 7 (SP1), MacOS X v.10.9.x -10.13.x, Linux v2.6+2, Citrix Ready (XenDesktop, XenApp compatible)",
  },
  {
    id: 10,
    name: "DataLocker Sentry 5",
    image: img6,
    desc1: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image1: cross,
    desc2: "Authentication Method",
    desc3: "Hardware Based",
    desc4: "AES-XTS 256-bit hardware encryption",
    image2: tick,
    desc5: "Capacity",
    desc6: "8/16/32/64/128/256/512GB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 (type A), USB-C accessory available for purchase",
    desc9: "Certifications",
    desc10: "FIPS 140-3 Level 3 certified (pending)",
    desc11: "Software free / Compatible with:",
    desc12: "Compatible with Windows® and macOS®. Limited capability on Linux. USB 3.0/USB 3.1/USB 3.2 Gen 1 compliant and 2.0 compatible",
  },
];