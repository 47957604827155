import React from "react";
import { Link } from "react-router-dom";

import "./aboutUsStyle.css";

const AboutUs = () => {
  return (
    <div>
      <div className="about-us">
        <h2 className=" display-4">TIETOA MEISTÄ</h2>
      </div>
      <div className="row">
        <div className="col-md-9 col-12 p-5">
          <h5 className="">
            Poliisihallitus on myöntänyt Lokkit Oy:lle turvallisuusalan
            elinkeinoluvan 27.11.2020.
          </h5>
          <p className="text-muted">
            Hyväksymistä edellyttäviä turvasuojaustehtäviä käsittävää
            turvasuojaustoimintaa saa harjoitaa vain yritys, joka on saanut
            siihen turvallisuusalan elinkeinoluvan.
          </p>
          <p>Toimimme iStoragen jakelijana.</p>
        </div>
        <div className="col-md-3 col-12 p-5">
          <h3>Ota yhteyttä</h3>
          <Link to="/contact-us">
            <button className="btn btn-success">CONTACT US</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
