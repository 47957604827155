import React from "react";
import "./footerStyle.css";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark p-4">
      <div className="container justify-content-center">
        <p className="text-center p-0 m-0 text-white fs-5">&copy; All rights reserved by Lokkit Oy</p>
      </div>
    </div>
  );
};

export default Footer;
