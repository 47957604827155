import React from "react";
import "./nagiosStyle.css";

const Nagios = () => {
  return (
    <div className="container-fluid p-0 m-0">
      <div className="col-12 nagios">
        <h1 className="text-center">NAGIOS</h1>
      </div>
      <div className="row p-3">
        <h4 className="text-center">Nagios</h4>
        <hr />
        <p>
          Lokkit Oy on Nagios partneri, saat meiltä ohjelmiston sekä asennuksen
          ja ylläpidon.
        </p>
        <h6>License Levels</h6>
        <p className=" text-muted">
          Nagios XI is available in multiple different license levels: 100 Node
          through Unlimited Node. A node is anything with an IP address or
          domain that you would like to monitor (switches, routers, firewalls,
          workstations, and other network devices). There are no restrictions on
          the number of services that can be monitored. Each license includes
          twelve months of maintenance (upgrade entitlements) and email support.
        </p>
        <h6>Standard Edition vs. Enterprise Edition</h6>
        <p className=" text-muted">
          Nagios XI is available in two different editions: Standard Edition and
          an Enterprise Edition. The Enterprise Edition provides users with
          additional functionality and includes features that are designed to
          aid in large-scale configuration, forecasting, and scheduled
          reporting. Each license includes twelve months of maintenance (upgrade
          entitlements) and email support.
        </p>
        <div className="row d-flex g-2 justify-content-evenly m-3">
          <div className="col-5 border p-2">
            <h6 className="text-center text-primary">Standard Edition</h6>
            <h6 className="text-center">From $1,995</h6>
            <ul className=" text-muted">
              <li>Easy Configuration Wizards</li>
              <li>GUI Configuration</li>
              <li>Advanced Reporting</li>
              <li>Enhanced Visualizations</li>
              <li>Custom User Dashboards</li>
              <li>Custom User Views</li>
              <li>Executive Summary Report</li>
              <li>Custom Actions</li>
              <li>Dashboard Deployment</li>
              <li>Notification Escalations</li>
            </ul>
          </div>
          <div className="col-5 g-2 border  p-2">
            <h6 className="text-center text-primary">Enterprise Edition</h6>
            <h6 className="text-center">From $3,495</h6>
            <h6 className="text-center">
              Everything in Standard Edition Plus:
            </h6>
            <ul className=" text-muted">
              <li>Scheduled Reports</li>
              <li>Capacity Planning Reports</li>
              <li>Web-Based Server Console Access</li>
              <li>Bulk-Modification Tools</li>
              <li>Audit Logging</li>
              <li>Notification Deployment</li>
              <li>SLA Reports</li>
              <li>Scheduled Pages</li>
              <li>Automated Host Decommissioning</li>
            </ul>
          </div>
          <p className="text-muted">
            *Note: Enterprise Edition licenses require annual renewal of
            Maintenance & Support
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nagios;
