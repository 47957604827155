import React from "react";
import "./tietoturvaStyle.css";

const Tietoturva = () => {
  return (
    <div className=" container-fluid p-0 m-0">
      <div className="col-12 myTietoTurvaContainer">
        <h4 className=" text-center">TIETOTURVA</h4>
      </div>
      <div className="col-12 p-3">
        <h4 className="text-center">Tietoturva</h4>
        <hr />
        <p>
          Turvallisuus muodostuu toimivasta kokonaisuudesta, josta
          tietoturvallisuus on yksi pieni osa kokonaisuutta.
        </p>
        <p className="text-muted">
          Tietoturvallisuudella tuloksia/yleisohje tietoturvallisuuden
          johtamiseen ja hallintaan (2007, 23 - 78) mukaan tietoturvallisuus on
          luokiteltu osa-alueisiin seuraavasti:
        </p>
        <ul className="text-muted">
          <li>Hallinnollinen turvallisuus</li>
          <li>Tietoturvallisuuden organisointi</li>
          <li>Tietoaineistoturvallisuus</li>
          <li>Henkilöstöturvallisuus</li>
          <li>Fyysinen turvallisuus</li>
          <li>Tietoliikennepalveluiden turvallisuus</li>
          <li>Laitteistoturvallisuus </li>
          <li>Käyttöturvallisuus</li>
          <li>Ohjelmisto ja ohjelmistokehityksen turvallisuus</li>
          <li>Jatkuvuuden ja erityistilanteiden hallinta</li>
        </ul>
        <p>Tietoturvallisuuden hallintajärjestelmän kypsyystaso</p>
        <h6>Tietoturvapoliitiikka</h6>
        <p className="text-muted">
          Hyvään tietohallintatapaan sisältyy velvollisuus huolehtia
          tiedonkäsittelyn asianmukaisesta suunnittelusta,
          tietoturvaratkaisuista ja testauksista, riskien hallinnasta,
          vahinkojen ja väärinkäytösten ennaltaehkäisystä sekä
          poikkeamaraportoinnista.
        </p>
        <h6>Ohjeistus ja perusohjeistus</h6>
        <p className="text-muted">
          Ohjeistaminen on keskeisessä roolissa, yrityksen antamista
          työnjohdollisista määräyksistä, hallinnollisista ohjeista,
          prosesseista, ratkaisuista ja teoista, jotka edistävät tavoitteeksi
          asetetun tietoturvallisuustason muodostumista.
        </p>
        <h6>Uhka- ja riskikartoitus</h6>
        <p className="text-muted">
          Uhka- eli riskikartoituksessa haetaan yrityksen toimintaan liittyvät
          riskit ja uhkakuvat. Ensin kannattaa tehdä yleinen tietoturvariskien
          kartoitus, jonka avulla nähdään yleisellä tasolla mitä riskejä
          tietojenkäsittelyyn sisältyy.
        </p>
        <h6>Riskienhallintasuunnitelma</h6>
        <p className="text-muted">
          Riskienhallintasuunnitelmassa olisi hyvä ottaa huomioon seuraavat
          Johdon tietoturvaopas (2011, 14), jossa organisaation
          tietoturvavelvoitteet seuraavasti:
        </p>
        <ul className="text-muted">
          <li>Lainmukaisuuden varmistaminen</li>
          <li>Riskienhallinnan- ja hallintajärjestelmän toteuttaminen</li>
          <li>Tietoturvapolitiikkaan sitoutuminen</li>
          <li>Tietoturvajohtaminen</li>
          <li>Tietoturvavastuuhenkilön nimeäminen</li>
          <li>Tietoturvallisuuden organisointi</li>
          <li>Tietoturvallisuuden toteutumisen varmistaminen </li>
          <li>Tietoturvallisuuden TTS-suunnitteluedellytysten luonti</li>
          <li>Poikkeama- ja erityistilanteiden hallinta</li>
          <li>Tietoturvaraportointivelvollisuuksista huolehtiminen</li>
        </ul>
      </div>
    </div>
  );
};

export default Tietoturva;
