import React from "react";
import "./contactStyle.css";

const ContactUs = () => {
  return (
    <>
      <div className="contact-us my-5"></div>
      <div className="container border-top mt-4 mb-4 p-4">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">Yhteystiedot</h1>
                <h5 className="card-subtitle mb-2 ">Lokkit Oy</h5>
                <address>
                  <p className="m-0 fs-5">Osoite:</p>
                  <p className="p-0 m-0 text-muted">Kalkerinlenkki 6</p>
                  <p className="p-0 m-0 text-muted">Lepsämä 01830</p>
                  <p className="p-0 m-0 text-muted">Finland</p>
                </address>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card ">
              <div className="card-body">
                <h1 className="card-title mb-4">Ota yhteyttä</h1>
                <h6 className="card-subtitle fs-6 mb-2 text-muted">
                  Lähetä sähköpostia tai soita numeroon
                </h6>
                <p className="m-0">
                  Sähköposti:{" "}
                  <a href="mailto:heikki.nykyri@lokkit.fi">
                    heikki.nykyri@lokkit.fi
                  </a>
                </p>
                <p>
                  <strong>Puhelin:</strong>
                  <span className="contact-details"> +358 40 676 3603</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
