import pimg1 from "./images/pimg1.jpg";
import pimg2 from "./images/pimg2.jpg";
import pimg3 from "./images/pimg3.jpg";
import pimg4 from "./images/pimg4.jpg";
import cross from "./images/crossed_symbol.png";
import tick from "./images/ticked_symbol.png";
import img1 from "./images/1.png";
import img2 from "./images/2.png";
import img3 from "./images/3.png";

export const dummyProduct = [
  {
    id: 1,
    name: "DISKASHUR® M²",
    image: pimg1,
    desc1: "Authentication Method",
    desc2: "Hardware Based",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: tick,
    desc5: "Capacity",
    desc6: "SSD: 240/500GB - 1/2TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10: "FIPS 140-3 Level 3 (pending)",
    desc11: "Software free / Compatible with:",
    desc12:
      "MS Windows, macOS, Linux, Android,Chrome, Thin Clients, Zero Clients,Embedded Systems, Citrix and VMware",
  },
  {
    id: 2,
    name: "DISKASHUR®²",
    image: pimg2,
    desc1: "Authentication Method",
    desc2: "Hardware Based",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: tick,
    desc5: "Capacity",
    desc6: "HDD: 500GB - 1/2/3/4/5TB SSD: 128/256/512GB - 1/2/4/8/16TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10: "None",
    desc11: "Software free / Compatible with:",
    desc12:
      "MS Windows, macOS, Linux, Android,Chrome, Thin Clients, Zero Clients,Embedded Systems, Citrix and VMware",
  },
  {
    id: 3,
    name: "DISKASHUR PRO®²",
    image: pimg3,
    desc1: "Authentication Method",
    desc2: "Hardware Based",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: tick,
    desc5: "Capacity",
    desc6: "HDD: 500GB - 1/2/3/4/5TB SSD: 128/256/512GB - 1/2/4/8/16TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10:
      "FIPS 140-2 Level 2/3, NCSC CPA, NLNCSA BSPA & NATO Restricted – Certified",
    desc11: "Software free / Compatible with:",
    desc12:
      "MS Windows, macOS, Linux, Android,Chrome, Thin Clients, Zero Clients,Embedded Systems, Citrix and VMware",
  },
  {
    id: 4,
    name: "DISKASHUR DT®²",
    image: pimg4,
    desc1: "Authentication Method",
    desc2: "Hardware Based",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: tick,
    desc5: "Capacity",
    desc6: "1/2/3/4/6/8/10/12/14/16/18/20/22 TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 SuperSpeed",
    desc9: "Certifications",
    desc10:
      "FIPS 140-2 Level 2/3, NCSC CPA, NLNCSA BSPA & NATO Restricted - certified",
    desc11: "Software free / Compatible with:",
    desc12:
      "MS Windows, macOS, Linux, Android,Chrome, Thin Clients, Zero Clients,Embedded Systems, Citrix and VMware",
  },
  {
    id: 5,
    name: "DataLocker DL4 FE",
    image: img1,
    desc1: "Authentication Method",
    desc2: "Hardware Based",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: tick,
    desc5: "Capacity",
    desc6: "SSD 1/2/4/7.6/15.3TB - TB HDD 500GB/1TB/2TB",
    desc7: "Interface",
    desc8:
      "USB-C on the device, compatible with USB 3.2, USB 2.0 (8 TB drives and under)",
    desc9: "Certifications",
    desc10:
      "FIPS 140-2 Level 3 - certified",
    desc11: "Software free / Compatible with:",
    desc12:
      "Microsoft Windows, macOS®, Linux® or any machine that supports a USB mass storage device.",
  },
  {
    id: 5,
    name: "DataLocker (IronKey) H300",
    image: img2,
    desc1: "Authentication Method",
    desc2: "Hardware Based or  Enterprise edition, which allows for remote management",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: cross,
    desc5: "Capacity",
    desc6: "HDD 500GB/1/2TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 Backwards Compatible",
    desc9: "Certifications",
    desc10:
      "None",
    desc11: "Software free / Compatible with:",
    desc12:
      "Windows 8.1/8/7/XP (Prof. and Home SP3), macOS 10.10 – 10.15, Linux 2.6+ or higher, Citrix Ready (XenDesktop, XenApp compatible)",
  },
  {
    id: 6,
    name: "DataLocker (IronKey) H350",
    image: img3,
    desc1: "Authentication Method",
    desc2: "Hardware Based or Enterprise edition, which allows for remote management",
    desc3: "AES-XTS 256-bit hardware encryption",
    image1: tick,
    desc4: "Common Criteria EAL5+ (hardware certified) secure microprocessor",
    image2: cross,
    desc5: "Capacity",
    desc6: "HDD 500GB/1/2TB",
    desc7: "Interface",
    desc8: "USB 3.2 Gen 1 Backwards Compatible Type A Male to Micro B cable included",
    desc9: "Certifications",
    desc10:
      "FIPS 140-2 Level 3 and NATO - certified",
    desc11: "Software free / Compatible with:",
    desc12:
      "Windows 8.1/8/7/XP (Prof. and Home SP3), macOS 10.10 – 10.15, Linux 2.6+ or higher, Citrix Ready (XenDesktop, XenApp compatible)",
  },
];
