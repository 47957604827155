import React from "react";
import "./App.css";
import { BrowserRouter, HashRouter } from "react-router-dom";

import Footer from "./Layouts/Footer/Footer";
import RouterHandlers from "./Routers/Routers";
import NavBarNew from "./Layouts/Navigation/NavBarNew";

const App = () => {
  return (
    <HashRouter>
      {/* Navbar */}
      <NavBarNew />
      {/* Handle all routes on this file */}
      <RouterHandlers />
      {/* Dark and light mode themes */}
      {/* <DarkMode /> */}
      {/* Footer */}
      <Footer />
    </HashRouter>
  );
};

export default App;
