import React from "react";
import { Link } from "react-router-dom";
import nagiosV from "./nagiosV.webm";
import "./servicesStyle.css";

const Services = () => {
  return (
    <div>
      <div className="services display-4">
        <h2 className=" display-5">PALVELUT</h2>
      </div>
      <div className="row justify-content-center myServiceContainer">
        <Link
          to="/tietoturva"
          className="col-12 col-md-6 col-lg-3 col-12 border p-4 m-2 servicesCard"
        >
          <div>
            <h4 className="text-center">TIETOTURVA</h4>
            <hr />
            <p>
              Turvallisuus muodostuu toimivasta kokonaisuudesta, josta
              tietoturvallisuus on yksi pieni osa kokonaisuutta.
            </p>
            <p className="text-muted">
              Tietoturvallisuudella tuloksia/yleisohje tietoturvallisuuden
              johtamiseen ja hallintaan (2007, 23 - 78) mukaan tietoturvallisuus
              on luokiteltu osa-alueisiin seuraavasti:
            </p>
            <ul className="text-muted">
              <li>Hallinnollinen turvallisuus</li>
              <li>Tietoturvallisuuden organisointi</li>
              <li>Tietoaineistoturvallisuus</li>
              <li>Henkilöstöturvallisuus</li>
              <li>Fyysinen turvallisuus</li>
              <li>Tietoliikennepalveluiden turvallisuus</li>
              <li>Laitteistoturvallisuus </li>
              <li>Käyttöturvallisuus</li>
              <li>Ohjelmisto ja ohjelmistokehityksen turvallisuus</li>
              <li>Jatkuvuuden ja erityistilanteiden hallinta</li>
            </ul>
          </div>
        </Link>
        <Link
          to="/konsultointi"
          className="col-12 col-md-6 col-lg-3 border p-4 m-2 servicesCard"
        >
          <div>
            <h4 className="text-center">KONSULTOINTI</h4>
            <hr />
            <p>Yritys toimii oikein, kun sen tulos paranee.</p>
            <p className=" text-muted fs-6">
              Teemme yhteistyötä ja olemme osa Miktech Consultia, joka auttaa
              pk-yrityksiä tekemään parempaa tulosta tehostamalla yritysten
              toimintaa.
            </p>
            <p className=" text-muted fs-6">
              Tehostamme myyntiä ja tuotantoa. Parannamme johtamista ja
              talousseurantaa. Kaikki näkyvät yrityksen tuloksessa. Vain
              kannattavat yritykset tuovat hyvinvointia niin omistajille kuin
              henkilökunnalle.
            </p>
            <p className=" text-muted fs-6">
              Asiantuntijamme ovat kokeneita yrittäjiä.
            </p>
            <p className=" text-muted fs-6">
              Konsultointimme on luottamuksellista. Pyrimme kehittämistyössä
              kokonaisvaltaiseen, käytännönläheiseen palveluun, jossa asiakas
              itse tekee kaikki päätökset ja ratkaisut vahvan kehitysprosessin
              ja asiantuntijan ohjaamana.
            </p>
          </div>
        </Link>
        <Link
          to="/nagios"
          className="col-12 col-md-6 col-lg-3 border p-4 m-2 servicesCard"
        >
          <div className="nagiosVideo">
            <h4 className="text-center">NAGIOS</h4>
            <hr />
            <p>
              Lokkit Oy on Nagios partneri, saat meiltä ohjelmiston sekä
              asennuksen ja ylläpidon.
            </p>
            <video
              style={{ maxWidth: "300px" }}
              controls
              loop
              className=" d-block"
            >
              <source src={nagiosV} type="video/mp4" />
            </video>
            <p className="text-muted">
              Nagios XI is available in multiple different license levels: 100
              Node through Unlimited Node. A node is anything with an IP address
              or domain that you would like to monitor (switches, routers,
              firewalls, workstations, and other network devices). There are no
              restrictions on the number of services that can be monitored. Each
              license includes twelve months of maintenance (upgrade
              entitlements) and email support.
            </p>
          </div>
        </Link>
        <Link
          to="/"
          className="col-12 col-md-6 col-lg-3 border p-4 m-2 servicesCard"
        >
          <div>
            <h4 className="text-center">TIETOSUOJA</h4>
            <hr />
          </div>
        </Link>
        <Link
          to="/"
          className="col-12 col-md-6 col-lg-3 border p-4 m-2 servicesCard"
        >
          <div>
            <h4 className="text-center">ICT-PALVELUT</h4>
            <hr />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Services;
