/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "../productStyle.css";
import { dummyProduct } from "./HDD_SDDData";

import Hdd_SsdList from "./HDD_SSDList";

const HddSdd = () => {
  return (
    <div className="container pt-4">
      
      <div className="row">
        {dummyProduct.map((product) => (
          <Hdd_SsdList product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
};

export default HddSdd;
