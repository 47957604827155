import React, { useState } from "react";

const Hdd_SsdList = ({ product }) => {
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <div className="col-12 col-md-4 col-lg-3 ">
      <img src={product.image} alt="hello" className=" img-fluid" />
      <h5 className="text-center mt-2">{product.name}</h5>
      <div className="row justify-content-between m-2">
        <p className="col-5 btn btn-group-sm btn-primary text-white disabled">
          Kysy hinta
        </p>
        <p
          className="col-5 btn btn-group-sm btn-primary text-white"
          onClick={toggleMenu}
        >
          Näytä lisää
        </p>
      </div>

      <div>
        <ul id="dropMenu" style={{listStyle: "none", display: showMenu ? "block" : "none" }}>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc1}</li>
          <li style={{color: "#52595D"}}>{product.desc2}</li>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc3}</li>
          <li>
            <img src={product.image1} alt="tick" />
          </li>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc4}</li>
          <li>
            <img src={product.image2} alt="tick" />
          </li>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc5}</li>
          <li style={{color: "#52595D"}}>{product.desc6}</li>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc7}</li>
          <li style={{color: "#52595D"}}>{product.desc8}</li>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc9}</li>
          <li style={{color: "#52595D"}}>{product.desc10}</li>
          <li style={{backgroundColor: "#E5E4E2", fontSize: "large"}}>{product.desc11}</li>
          <li style={{color: "#52595D"}}>{product.desc12}</li>
        </ul>
      </div>
    </div>
  );
};

export default Hdd_SsdList;
