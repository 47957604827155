import React from "react";
import "./consultationStyle.css";

const Consultation = () => {
  return (
    <div className="container-fluid p-0 m-0">
      <h1 className="consulting">KONSULTOINTI</h1>
      <div className="row">
        <h4 className=" text-dark text-center">Konsultointi</h4>
        <hr />
        <div className="col-12 p-4">
          <h6>Yritys toimii oikein, kun sen tulos paranee</h6>
          <p className="text-muted">
            Teemme yhteistyötä ja olemme osa Miktech Consultia, joka auttaa
            pk-yrityksiä tekemään parempaa tulosta tehostamalla yritysten
            toimintaa.
          </p>
          <p className="text-muted">
            Tehostamme myyntiä ja tuotantoa. Parannamme johtamista ja
            talousseurantaa. Kaikki näkyvät yrityksen tuloksessa. Vain
            kannattavat yritykset tuovat hyvinvointia niin omistajille kuin
            henkilökunnalle.
          </p>
          <p className="text-muted">
            Asiantuntijamme ovat kokeneita yrittäjiä.
          </p>
          <p className="text-muted">
            Konsultointimme on luottamuksellista. Pyrimme kehittämistyössä
            kokonaisvaltaiseen, käytännönläheiseen palveluun, jossa asiakas itse
            tekee kaikki päätökset ja ratkaisut vahvan kehitysprosessin ja
            asiantuntijan ohjaamana.
          </p>
          <h6>Analyysi 1 – 2 pv</h6>
          <p className="text-muted">
            Päivähinta asiakkaalle 260,00 € + alv 24 % ELY tuettuna.
          </p>
          <h6>Kehittäminen 3 – 5 pv</h6>
          <p className="text-muted">
            Päivähinta asiakkaalle 325,00 € + alv 24 % ELY tuettuna.
          </p>
          <h6>Numerot</h6>
          <ul className=" text-muted">
            <li>Oikaistu tuloslaskelma</li>
            <li>BEP = Nollatuloksen rajapinta</li>
            <li>Tulosanalyysi ja ennusteet</li>
          </ul>
          <h6>Keskustelu</h6>
          <ul className=" text-muted">
            <li>Miten tultu nykytilanteeseen?</li>
            <li>Millainen tilanteen piti olla tässä vaiheessa?</li>
            <li>Mitä halutaan tulevaisuudelta</li>
          </ul>
          <h6>Kehittämistoimenpiteen päättäminen</h6>
          <ul className=" text-muted">
            <li>Myynti</li>
            <li>Johtaminen</li>
          </ul>
          <h6>Mitä tehyty?</h6>
          <ul className=" text-muted">
            <li>Miten päästy toteutuneisiin myynteihin?</li>
            <li>Toimintamallin analyysi</li>
            <li>Toimintamallin vaiheiden määrittely</li>
          </ul>
          <h6>Määritetään tärkeimmät asiakkaat ja tuotteet</h6>
          <ul className=" text-muted">
            <li>
              Määritetään tärkeimmät/tuottavimmat asiakkaat/asiakasryhmät.
            </li>
            <li>Määritellään päätuotteet tavoitteelliselle myynnille.</li>
            <li>Etsitään ylösmyynti- ja lisämyyntituotteet päätuotteille.</li>
          </ul>
          <h6>Tehdään tavoitteellisen myynnin malli</h6>
          <ul className=" text-muted">
            <li>Tehdään toimintamalli myynnin tekemiselle. </li>
            <li>Määritetään myynnin tavoitteet.</li>
            <li>Tehdään mittarit myynnin laadun mittaamiselle.</li>
            <li>Tehdään myynnin johtamisen mittarit ja malli.</li>
          </ul>
          <h6>
            Tehokas henkilökohtainen johtaminen toimitusjohtajalle ja ylemmälle
            johdolle
          </h6>
          <p className="m-0 p-0">LMI Oman minän johtaminen®</p>
          <p className="m-0 p-0">Oman johtamisen arviointi</p>
          <p className="m-0 p-0">Vahva itseluottamus</p>
          <p className="m-0 p-0">Menestyksen kuusi tunnuspiirrettä</p>
          <ul className=" text-muted">
            <li>Henkilökohtainen vastuu </li>
            <li>Tarkoituksen löytäminen</li>
            <li>Selkeät päämäärät ja suunnitelmat.</li>
            <li>Palava halu</li>
            <li>Positiivinen odotus</li>
            <li>Päättäväisyys</li>
          </ul>
          <p className=" m-0 p-0">Onnistunut kommunikaatio</p>
          <p className=" m-0 p-0">
            Moninkertaista johtajuutesi valtaistamalla
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
