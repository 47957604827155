import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home/Home";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Dashboard from "../pages/Profile/Dashboard";
import Services from "../pages/Services/Services";
import Products from "../pages/Products/Products";
import ContactUs from "../pages/Contact/ContactUs";
import AboutUs from "../pages/About/AboutUs";
import Consultation from "../components/Consultation/Consultation";
import Tietoturva from "../components/Tietoturva/Tietoturva";
import Nagios from "../components/Nagios/Nagios";

const RouterHandlers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/palvelut" element={<Services />} />
      <Route path="/tuotteet" element={<Products />} />
      <Route path="/ota-yhteytta" element={<ContactUs />} />
      <Route path="/tietoa-meista" element={<AboutUs />} />
      <Route path="/konsultointi" element={<Consultation />} />
      <Route path="/tietoturva" element={<Tietoturva />} />
      <Route path="/nagios" element={<Nagios />} />
    </Routes>
  );
};

export default RouterHandlers;
